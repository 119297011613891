// Open Sans использует rgs
@import './fonts.css';

$primary: #3f3f3f;
$input-bg: #f3f3f3;
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI',
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
//Все стили bootstrap

.tip {
  pointer-events: none;
}

.tip .tooltip-inner {
  background-color: #7f7f7f;
  //background-color: $secondary;
  color: #fff;
  max-width: 100%;
  border: 1px solid #7f7f7f;
  font-weight: 'normal';
  font-family: 'Roboto';
}
.tip .tooltip.bs-tooltip-right .arrow:before {
  border-right-color: #7f7f7f !important;
}
.tip .tooltip.bs-tooltip-left .arrow:before {
  border-left-color: #7f7f7f !important;
}
.tip .tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #7f7f7f !important;
}
.tip .tooltip.bs-tooltip-top .arrow:before {
  border-top-color: #7f7f7f !important;
}

@import '~bootstrap/scss/bootstrap.scss';
