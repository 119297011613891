html {
  font-size: 20px;
}

body {
  font-size: 18px !important;
}

body.noscroll {
  position: fixed;
  overflow: hidden;
}
